@import "react-phone-input-2/lib/style.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.phone-input-container {
    @apply w-full;
}

.phone-input-container .form-control {
    @apply w-full p-2 pl-12 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none text-base;
    height: 42px !important;
    width: 100% !important;
    border-color: #D1D5DB !important; /* matches border-gray-300 */
}

/* Style for the button containing the flag */
.phone-input-container .flag-dropdown {
    @apply border-0 bg-transparent rounded-l-lg;
    height: 42px !important;
}

/* Style for the button when phone input has focus */
.phone-input-container .flag-dropdown.open {
    @apply bg-transparent;
    border: none !important;
}

/* Remove the default border and background */
.phone-input-container .selected-flag {
    @apply bg-transparent hover:bg-gray-50;
    height: 42px !important;
}

/* Style for the dropdown containing countries */
.phone-input-container .country-list {
    @apply shadow-lg border border-gray-200 rounded-lg;
    margin-top: 4px;
}

/* Style for the search input in dropdown */
.phone-input-container .search-box {
    @apply p-2 border-b border-gray-200;
}

/* Style for each country item in dropdown */
.phone-input-container .country {
    @apply p-2 hover:bg-gray-50;
}

/* Style for the error state */
.phone-input-container.error .form-control {
    @apply border-red-500;
}

.phone-input-container.error .flag-dropdown {
    @apply border-red-500;
}